import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { crmAuthGuard } from 'common-module/auth';

import { LayoutOutletComponent } from '~/shared/components/layout/layout-outlet.component';

import { AppGuard } from './app.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('common-module/auth').then((m) => m.authRoutes),
  },
  {
    path: '',
    canActivate: [crmAuthGuard()],
    canActivateChild: [
      (route: ActivatedRouteSnapshot) =>
        inject(AppGuard).canActivateChild(route),
    ],
    component: LayoutOutletComponent,
    children: [
      {
        path: 'news',
        loadChildren: () => import('./features/news/routes'),
        data: { permission: 'news' },
      },
      {
        path: 'menu',
        loadChildren: () => import('./features/menu/routes'),
        data: { permission: 'meal' },
      },
      {
        path: 'contacts',
        loadChildren: () => import('./features/contacts/routes'),
        data: { permission: 'contacts' },
      },
      {
        path: 'benefits',
        loadChildren: () => import('./features/benefits/routes'),
        data: { permission: 'benefit' },
      },
      {
        path: 'collective-agreement',
        loadChildren: () => import('./features/collective-agreement/routes'),
        data: { permission: 'collective-agreement' },
      },
      {
        path: 'jobs',
        loadChildren: () => import('./features/jobs/routes'),
        data: { permission: 'job' },
      },
      {
        path: 'questions',
        loadChildren: () => import('./features/questions/routes'),
        data: { permission: 'ticket' },
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/routes'),
        data: { role: ['admin'] },
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/users/profile-routes'),
      },
      {
        path: '**',
        redirectTo: 'news',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
